import _debounce from "lodash/debounce"
import _isEqual from "lodash/isEqual"
import store from "@/store"
import _cloneDeep from "lodash/cloneDeep"
import { getFromStorage } from './firebase.js'
import { LogEvent } from "./analytics"
import { version } from "jszip"

let config = {
  version: 4,
  stores: [
    { name: 'images',
      keyPath: 'imageID',
      indexes: [
        { name: 'time', path: 'ts', unique: false }
      ]
    },
    { name: 'external',
      keyPath: 'fileID',
      indexes: [
        { name: 'time', path: 'ts', unique: false }
      ]
    },
    { name: 'h3tiles',
      keyPath: 'tile',
      indexes: [
        { name: 'time', path: 'ts', unique: false }
      ]
    },
    { name: 'featureTiles',
      keyPath: 'tile',
      indexes: [
        { name: 'time', path: 'ts', unique: false }
      ]
    },
  ]
}
let ready = false
let localDb = false
const dbErrorHandle = function (event) {
  console.log('Database error: ', event.target.errorCode, event)
}
const dbInit = (next) => {
  var request = indexedDB.open('trakk-assets', config.version)
  request.onerror = function () {
    alert('Error initialising database: your browser must support IndexedDB in order to cache files offline');
    next(true)
  }
  request.onupgradeneeded = (event) => {
    var db = event.target.result
    db.onerror = dbErrorHandle

    // db.deleteObjectStore('user')
    // db.createObjectStore('user')

    if (event.oldVersion >= 1) {
      config.stores.forEach((s) => {
        try {
          db.deleteObjectStore(s.name)
        } catch (err) {
          console.log(err);
        }
      })
    }
    config.stores.forEach((s) => {
      let resourceStore = db.createObjectStore(s.name, { keyPath: s.keyPath })
      s.indexes.forEach((i) => {
        resourceStore.createIndex(i.name, i.path, { unique: i.unique })
      })
    })
  }
  request.onsuccess = (event) => {
    localDb = event.target.result
    localDb.onerror = dbErrorHandle
    ready = true
    next()
  }
}
const putRecord = ({ objStore, key, data, ts, mimeType, version }) => {
  return new Promise((resolve, reject) => {
    if (!objStore || !key || !data || !ts) {
      let err = new Error('missing required parameters')
      console.warn(err, { objStore, key, data, ts, mimeType })
      return reject(err)
    }
    if (!mimeType) mimeType = ''
    const storeFile = () => {
      let record = { ts, mimeType, data, version }
      let tr = localDb.transaction(objStore, 'readwrite')
      let store = tr.objectStore(objStore)
      store.onerror = dbErrorHandle
      let keyPath = store.keyPath
      if(!keyPath) return console.error('objectStore ' + objStore + ' does not have keyPath')
      if(data[keyPath] !== undefined && data[keyPath] !== key) return console.warn('data[' + keyPath + '] does not match dbase key')
      record[keyPath] = key
      let put = store.put(record)
      put.onsuccess = () => { resolve(record) }
      put.onerror = (e) => {
        console.warn(e)
        reject(e)
      }
    }
    if (data instanceof Blob) {
      mimeType = data.type
      var reader = new FileReader()
      reader.addEventListener("loadend", function () {
        data = reader.result
        storeFile()
      });
      reader.readAsArrayBuffer(data);
    } 
    else {
      if (typeof data === 'object') data = JSON.stringify(data)
      if(typeof data !== 'string') {
        let err = new Error('invalid data type for local storage')
        console.warn(err, { objStore, key, data, ts, mimeType })
        return reject(err)
      }
      storeFile()
    }
  })
}
const getRecord = function ({ objStore, key }) {
  return new Promise((resolve, reject) => {
    if (!objStore || !key) {
      let err = new Error('missing required parameters')
      return reject(err)
    }
    let tr = localDb.transaction(objStore, 'readwrite')
    let get = tr.objectStore(objStore).get(key)
    get.onsuccess = (e) => {
      resolve(get.result || false)
    }
    get.onerror = (e) => {
      console.warn(e)
      reject(false)
    }
  })
}
const getFile = function ({ objStore, key }) {
  return new Promise((resolve, reject) => {
    getRecord({ objStore, key })
    .then((record) => {
      if (!record) return resolve(false)
      let file = new Blob([record.data], { type: record.mimeType })
      let url = window.URL.createObjectURL(file)
      resolve({ key, file, url, ts: record.ts, version: record.version || 'default' })
    })
    .catch((err) => {
      reject(err)
    })
  })
}
const downloadFile = function ({ objStore, key }) {
  return new Promise((resolve, reject) => {
    getFile({ objStore, key })
    .then(async (record) => {
      if (!record) {
        console.log('Could not download, file not found in local cache')
        return reject()
      }
      let fileName = record.key
      switch(record.file.type) {
        case 'application/pdf':
          fileName += '.pdf'
          break;
        case 'application/json':
          fileName += '.json'
          break;
        case 'image/jpeg':
          fileName += '.jpg'
          break;
        case 'image/png':
          fileName += '.png'
          break;
        default:
          console.log('unknown type:', record.file.type)
      }
      if (record.file.type === 'application/pdf') {
        resolve({
          message: 'success',
          file: {
            url: record.url,
            type: record.file.type,
            name: fileName
          }
        })
      } else {
        let a = window.document.createElement("a");
        a.href = record.url;
        a.download = fileName;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        resolve('success')
      }
      
      LogEvent('image_download')
    })
    .catch((err) => {
      console.log('local db download err')
      console.log(err)
      reject(err)
    })
  })
}
const cacheFileExternal = async ({ fileID, src, ts }) => {
  // console.log('Cache file external', { fileID, src, ts })
  let key = fileID
  let objStore = 'external'
  let cacheFile = await getFile({ objStore, key })
  let currentTs = new Date((cacheFile || {}).ts || 0)
  let storeTs = new Date(ts)
  if (currentTs > storeTs) return cacheFile
  let url = ''
  // let url = 'https://australia-southeast1-trakkasset.cloudfunctions.net/proxy/'
  url += src.startsWith('http') ? '' : 'https://'
  url += src
  const fetchResponse = await fetch(url)
  .catch((err) => { 
    // console.warn(err)
    return false 
  })
  // console.log(fetchResponse)
  let record = { objStore, key, ts: new Date(), version: 'default' }
  if(!fetchResponse || !fetchResponse.ok) {
    record.data = { error: 'fetch error', status: fetchResponse.status }
    record.mimeType = 'external/nofetch'
  }
  else {
    record.data = await fetchResponse.blob()
  }
  // console.log(fetchResponse)
  const putResult = await putRecord(record)
    .catch((err) => {
      console.warn(err)
      return false
    })
  if(!putResult) return { error: 'localDB error' }
  // console.log(putResult)
  return putResult
}
const cacheFileFirebase = async ({ fileID, src, ts }) => {
  let dbPath = src.split('/')
  let key = dbPath[2]
  let objStore = dbPath[1]
  if (!ts) return console.warn('ERROR: no ts in cacheList', { fileID, src, ts })
  if (dbPath.length > 3) {
    for (let i = 3; i < dbPath.length; i++) key += '/' + dbPath[i]
  }
  let ref = { objStore, key }
  let cacheFile = await getFile(ref)
  let currentTs = new Date((cacheFile || {}).ts || 0)
  let storeTs = new Date(ts)
  if (currentTs < storeTs) {
    console.log(`Cache file: ${src}`)
    // console.log({ 'currentTs': currentTs, 'storeTs': storeTs })
    let storePath = `${objStore}/${key}`
    let version = 'default'
    let storageResult = await getFromStorage(storePath).catch(err => {
      console.warn(err)
      return null
    })
    // console.log(storageResult)
    if(!storageResult) return false
    putRecord({ objStore: dbPath[1], key, data: storageResult, ts, version }).catch(err => {
      if (err) {
        console.warn(err)
        return false
      }
    })
    return true
  }
}

// manage cachelist
let cacheNowStatus = 'idle'
let lastCacheList = []
const cacheNow = () => {
  cacheNowStatus = 'running'
  let cacheList = store.getters.cacheList
  lastCacheList = _cloneDeep(cacheList)
  let fileRefs = []
  let pushRef = (key) => {
    let dbRef = store.getters.imageByID(key)
    if(dbRef) fileRefs.push({ fileID: dbRef.imageID, src: dbRef.src, ts: dbRef.ts })
    else {
      dbRef = store.state.Db.files[key]
      if(dbRef) fileRefs.push({ fileID: dbRef.fileID, src: dbRef.src, ts: dbRef.ts })
    } 
  }
  cacheList.forEach(pushRef)
  fileRefs.forEach((meta) => {
    if (typeof meta.src === 'string') {
      if (meta.src.startsWith('gs:')) cacheFileFirebase(meta)
      else cacheFileExternal(meta)
    } else if (Array.isArray(meta.src)) {
      meta.src.forEach((src, i) => {
        if (src.startsWith('gs:')) cacheFileFirebase({ src, ts: meta.ts })
        else cacheFileExternal({ fileID: meta.fileID + '-' + i, src, ts: meta.ts})
      })
    }
  })
  cacheNowStatus = setTimeout(() => { cacheNowStatus = 'idle' }, 500)
}
const cacheUpdate = _debounce(() => {
  let newCacheList = store.getters.cacheList
  if(!_isEqual(lastCacheList, newCacheList)) {
    if(cacheNowStatus === 'idle') cacheNow()
    else {
      setTimeout(cacheUpdate, 1000)
    }
  }
}, 500) 

export {
  localDb,
  ready,
  dbInit,
  putRecord,
  getRecord,
  getFile,
  downloadFile,
  cacheFileExternal,
  cacheFileFirebase,
  cacheUpdate,
}
import { Timestamp } from 'firebase/firestore'

/**
 * Generates a formatted date string for file naming
 * Format: YYYY-MM-DD_HHMM
 */
export const tsNowString = () => {
    let d = new Date()
    let date = d.getDate()
    let month = d.getMonth() + 1
    let fullyear = d.getFullYear()
    let hour = d.getHours()
    let minutes = d.getMinutes()

    month = month < 10 ? '0' + month : month;
    date = date < 10 ? '0' + date : date;
    hour = hour < 10 ? '0' + hour : hour
    minutes = minutes < 10 ? '0' + minutes : minutes
    return `${fullyear}-${month}-${date}_${hour}${minutes}`
}

/**
 * Formats a date value into a string based on the specified format
 */
export const formatDateString = (value, format) => {
    let d = value ? new Date(value) : new Date()
    if(!d) return null
    let date = d.getDate()
    let month = d.getMonth() + 1
    let year = d.getYear()
    let fullyear = d.getFullYear()
    let hour = d.getHours()
    let minutes = d.getMinutes()

    month = month < 10 ? '0' + month : month;
    date = date < 10 ? '0' + date : date;
    year = year > 100 ? year - 100 : year
    hour = hour < 10 ? '0' + hour : hour
    minutes = minutes < 10 ? '0' + minutes : minutes

    if(format === 'yyyy') return `${fullyear}`
    else if(format === 'yyyy-mm') return `${fullyear}-${month}`
    else if(format === 'yyyy-mm-dd') return `${fullyear}-${month}-${date}`
    else if(format === 'yyyy-mm-dd HH:MM') return `${fullyear}-${month}-${date} ${hour}:${minutes}`
    else if(['long', true].includes(format)) return `${date}/${month}/${fullyear} ${hour}:${minutes}`
    return `${date}/${month}/${year}`
}

/**
 * Converts various date formats to Firebase Timestamp
 */
export const FirebaseTimestamp = (value, format) => {
    if(!value || value === null) return null
    if(value instanceof Date) return Timestamp.fromDate(value)
    if(typeof value === 'number') return Timestamp.fromMillis(value)
    if(typeof value !== 'string') {
        console.log('dateError', { value })
        return null
    }
    if(format) {
        let date = new Date(0)
        if(format === 'yyyy') {
            date.setFullYear(parseInt(value))
        }
        else if(format === 'yyyy-mm') {
            let parts = value.split('-')
            date.setFullYear(parseInt(parts[0]))
            date.setMonth(parseInt(parts[1]) - 1)
        }
        else if(format === 'yyyy-mm-dd') {
            let parts = value.split('-')
            date.setFullYear(parseInt(parts[0]))
            date.setMonth(parseInt(parts[1]) - 1)
            date.setDate(parseInt(parts[2]))
        }
        else if(format === 'yyyy-mm-dd HH:MM') {
            let parts = value.split(' ')
            let dateParts = parts[0].split('-')
            let timeParts = (parts[1] || '00:00').split(':')
            date.setFullYear(parseInt(dateParts[0]))
            date.setMonth(parseInt(dateParts[1]) - 1)
            date.setDate(parseInt(dateParts[2]))
            date.setHours(parseInt(timeParts[0]))
            date.setMinutes(parseInt(timeParts[1]))
        }
        else {
            return null
        }
        return Timestamp.fromDate(date)
    }
    return Timestamp.fromDate(new Date(value))
}

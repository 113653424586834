<template>
  <div>
    <slot></slot>
  </div>
</template>

<script>

export default {
  name: 'AppPage'
}
</script>

<style lang="scss">
  // Import Main styles for this application
  @import 'assets/scss/style';
</style>

var fbConfig = {
    apiKey: "AIzaSyDlAg46jX3iq6M_uw9zRr6OC3OE16ZIBvo",
    authDomain: "trakkasset.firebaseapp.com",
    databaseURL: "https://trakkasset.firebaseio.com",
    projectId: "trakkasset",
    storageBucket: "trakkasset.appspot.com",
    messagingSenderId: "512546457350",
    appId: "1:512546457350:web:a997cb0afe8b543f29c087",
    measurementId: "G-8PRQWET0ZC"
}

export { fbConfig }
export default {
  methods: {
    setAlert(notice, type, timeout=5000) {
      if (!type) type = 'info'
      this.$store.commit('SET_ALERT', { 
        notice, 
        variant: type,
        timeout
      })
    }
  }
}
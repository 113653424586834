import { loadOutseta } from "@/lib/outseta";
let Outseta = false

const setToken = async (token) => {
    if(!Outseta) return console.log('Outseta not init')
    await Outseta.setAccessToken(token.access_token)
    console.log('Outseta login OK, subscription: ', token.subscription)
}
const init = async () => {
    Outseta = await loadOutseta();
    if(Outseta) console.log('Outseta loaded OK')
    else console.log('Outseta not loaded')
    return true
}
const logout = async () => {
    if(!Outseta) return console.log('Outseta not init')
    await Outseta.setAccessToken("");
    console.log('Outseta logout OK')
}
const openProfile = async (options) => {
    if(!Outseta) return console.log('Outseta not init')
    Outseta.profile.open({ tab: "profile", ...options });
};

export default {
    setToken,
    init,
    logout,
    openProfile
}
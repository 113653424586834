const OUTSETA_ID = "Outseta";

const OPTIONS = {
  id: OUTSETA_ID,
  domain: "trakkassets.outseta.com",
  monitorDom: true, /* Vital setting for a single page application */
  load: "auth,profile,support", // load options: auth,chat,leadCapture,nocode,profile,support
  auth: {
    "widgetMode": "register",
    "planFamilyUid": "OW4NkXQg",
    "planPaymentTerm": "month",
    "skipPlanOptions": true,
    "id": "signup_embed",
    "mode": "embed",
    "selector": "#signup-embed"
  },
  profile: {
    id: "account_embed",
    selector: "#account-embed",
    mode: "embed",
    tabs: 'account,plan,planCancel,planChange,billing',
    tab: 'account'
  },
  support: {
      mode: 'embed',
      selector: '#support-embed'
  }
};

let outsetaPromise = null;

const createScript = () => {
  const optionsKey = `${OUTSETA_ID}Options`;

  // Set the options on window
  window[optionsKey] = OPTIONS;

  // Create external script element
  const script = document.createElement("script");
  script.src = "https://cdn.outseta.com/outseta.min.js";
  // Set name of options key on window
  script.dataset.options = optionsKey;

  return script;
};

export const loadOutseta = async () => {
  if (outsetaPromise) return outsetaPromise; // Return promise if already exists.
  outsetaPromise = new Promise((resolve, reject) => {
    if (window[OUTSETA_ID]) {
      // If Outseta is initialized
      // lets resolve right away
      resolve(window[OUTSETA_ID]);
    } else {
      const script = createScript();

      script.onload = () => {
        if (window[OUTSETA_ID]) {
          resolve(window[OUTSETA_ID]);
        } else {
          reject(new Error("Outseta.js not available"));
        }
      };

      script.onerror = () => {
        reject(new Error("Failed to load Outseta.js"));
      };

      document.head.appendChild(script);
    }
  });
  return outsetaPromise;
};
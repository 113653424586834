export default {
  computed: {
    userRoles() {
      let roles = this.$store.getters.orgConfig.roles || [];
      let result = {}
      roles.forEach((r) => {
        result[r] = true
      })
      let sysAdmin = this.$store.getters.isAdmin
      if(sysAdmin) {
        result.sysAdmin = true
        result.admin = true
      }
      if(result.admin) {
        result.editor = true
        result.commit = true
      }
      return result
    },
    userID() {
      return this.$store.state.Auth.configUser.id
    }
  }
}
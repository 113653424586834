// ~store/index.js
import Vue from 'vue'
import Vuex from 'vuex'

import Db from './modules/db.js'
import Auth from './modules/auth.js'
import Util from './modules/util.js'
import Nfc from './modules/nfc.js'
import { fsMutations } from './firebase.js'
import _cloneDeep from "lodash/cloneDeep";
import _isEqual from "lodash/isEqual";
import _debounce from "lodash/debounce";
import _find from "lodash/find";
import _forEach from "lodash/forEach";
import { cacheUpdate } from "./localDb"
import { updateSchema } from '@/lib/schema'

import LogRocket from 'logrocket';
import createPlugin from 'logrocket-vuex';

const logRocketPlugin = createPlugin(LogRocket, function(mutation) {
  if (mutation.type === 'set_fs') {
    let m = Object.assign({}, mutation)
    delete m.payload
    m.key = mutation.payload.key
    if(Array.isArray(mutation.payload.data)) {
      m.dataLength = mutation.payload.data.length
    } 
    else if(typeof mutation.payload.data == "object") {
      m.dataLength = Object.keys(mutation.payload.data).length
    }
    else {
      m.payload = mutation.payload
    }
    return m;
  }
  return mutation;
});
const plugins = []
// const envMode = process.env.NODE_ENV
// if(envMode !== 'development') plugins.push(logRocketPlugin)
plugins.push(logRocketPlugin)

Vue.use(Vuex)

const storeConfig = {
  plugins: [logRocketPlugin],
  mutations: {
    ...fsMutations
  },
  modules: {
    Db,
    Auth,
    Util,
    Nfc
  }
}

// initialise Vuex
const store = new Vuex.Store(storeConfig)

let setParents = _debounce(parents => {
  store.commit('SET_PARENTS', parents)
}, 500, { maxwait: 2000 })

store.watch(
  (state) => { 
    return { assets: state.Db.assets, parents: state.Db.parents } 
  },
  (newData, oldData) => {
    // console.log('calculate parents', newData)
    let parents = {}
    _forEach(newData.assets, c => {
      if (c.parent === null && !parents[c.assetID]) parents[c.assetID] = []
      else if (c.parent) {
        if (parents[c.parent]) parents[c.parent].push(c.assetID)
        else {
          let parentAsset = _find(newData.assets, a => {
            return a.assetID === c.parent
          })
          if(parentAsset) parents[c.parent] = [c.assetID]
        }
      }
    })
    if(!_isEqual(parents, oldData.parents)) {
      setParents(parents)
    }
  }
)

store.watch(
  (state) => {
    return state.Util.imageCache || []
  },
  (newList, oldList) => {
    if(!_isEqual(newList, oldList)) cacheUpdate()
  }
)

store.watch(
  (state) => {
    return state.Db.org || []
  },
  (newOrg, oldOrg) => {
    let oldSchema = {
      attributes: oldOrg.schema?.attributes || {},
      lists: oldOrg.schema?.lists || {},
      templates: oldOrg.schema?.templates || {},
      types: oldOrg.schema?.types || {},
      groups: oldOrg.schema?.setup?.groups || {}
    }
    let newSchema = {
      attributes: newOrg.schema?.attributes || {},
      lists: newOrg.schema?.lists || {},
      templates: newOrg.schema?.templates || {},
      types: newOrg.schema?.types || {},
      groups: newOrg.schema?.setup?.groups || {}
    }
    if(!_isEqual(oldSchema, newSchema)) {
      // console.log('schema changed', {
      //   attributes: _isEqual(oldSchema.attributes, newSchema.attributes),
      //   lists: _isEqual(oldSchema.lists, newSchema.lists),
      //   templates: _isEqual(Object.keys(oldSchema.templates), Object.keys(newSchema.templates)),
      //   types: _isEqual(Object.keys(oldSchema.types), Object.keys(newSchema.types)),
      //   groups: _isEqual(oldSchema.groups, newSchema.groups)
      // }, oldSchema.templates, newSchema.templates)
      updateSchema()
    }
  }
)


export default store
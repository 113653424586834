const encoder = new TextEncoder()
const decoder = new TextDecoder()

export default {
    state: {
      nfcStatus: 'init',
      init: null,
      scan: null,
      toWrite: null,
      tag: {
        code: null,
        aid: null,
        data: null
      },
      callbackRef: {
        cb: null,
        acb: null
      },
      callback: null,
      autocallback: null
    },
    mutations: {
      SET_NFC_WRITE(state, toWrite) {
        state.toWrite = toWrite
      },
      SET_NFC_TAG(state, tag) {
        state.tag.code = tag?.code || null
        state.tag.aid = tag?.aid || null
        state.tag.data = tag?.data || null
      },
      SET_NFC_CALLBACK(state, { cb, key }) {
        if(cb === null) key = null
        state.callbackRef.cb = key
        state.callback = cb
      },
      SET_NFC_AUTOCALLBACK(state, { cb, key }) {
        // console.log('SET_NFC_AUTOCALLBACK', key)
        if(cb === null) key = null
        state.callbackRef.acb = key
        state.autocallback = cb
      },
      SET_NFC_STATUS(state, value) {
        state.nfcStatus = value
      },
      SET_NFC_INIT(state, value) {
        state.init = value
      },
      SET_NFC_SCAN(state, value) {
        state.scan = value
      }
    },
    actions: {
      nfcReadTag({ commit, state, rootState, getters }, event) {
        console.log('nfcReadTag', event)
        let toHex = (d) => { return d.toString(16).padStart(2, "0") }
        let arrayToHex = (d) => { 
          let s = ""
          d.forEach(e => { s += toHex(e) })
          return s
        }
        
        let serialNumber = event.serialNumber || null
        let message = event.message || {}
        if(!serialNumber) {
          serialNumber = event.nfcTag.id ? String(arrayToHex(event.nfcTag.id)).toUpperCase() : null
          message = event.nfcTag.message || {}
        }
        if(!serialNumber) { return console.log('NFC read error: could not resolve serial number', event) }

        return new Promise((resolve, reject) => {
          let serialNumberArray = String(serialNumber).split(':')
          let code = ""
          serialNumberArray.forEach(n => { code += n })
          console.log(`Tag: ${code}`)
          let tag = { code, data: [], aid: null }
          let tagDB = rootState.Db.tags[code.toUpperCase()] || {}
          if(tagDB.assetID) {
            tag.aid = tagDB.assetID
            // console.log('tag.aid from DB', tag.aid)
          }

          let records = message.records || []

          // The following code is not used. Shows how we might handle data stored
          // on the tag in the future. For now, we match serialNumber to database record
          // and use that to get the assetID
          if(String(records[0]?.id).startsWith('trakkassets.com/')) {
            console.log(`Trakk tag: ${serialNumber}`)
            records.forEach(r => {
              console.log(`record: ${r.id}, type: ${r.recordType}`)
              if(r.recordType === 'mime') console.log(`mimeType: ${r.mediaType}`)
              
              if(r.mediaType === 'application/json' && String(r.id).startsWith('trakkassets.com/')) {
                let d = JSON.parse(decoder.decode(r.data))
                let type = String(r.id).split('/#/')[1]
                tag.data.push({ type, data: d })
                console.log(`record: ${r.id}`, d)
  
                if(type === 'asset') {
                  let schemaSetup = rootState.Db.schema?.merge?.setup || {}
                  let idtype = String(schemaSetup.idType || 'AssetID').toLowerCase()
                  let messageAid = d[idtype] || d.assetid || null
                  if(messageAid) {
                    if(tag.aid !== messageAid) return console.log('Duplicate / inconsistent asset record found on tag')
                    tag.aid = String(messageAid)
                  }
                }
              }
            })
            console.log('Read message. TAG = ', tag)
          } else {
            if(!message[0]) {
              console.log(`No message`)
            }
            else {
              console.log(message)
            }
          }
          if(tag.data.length === 0) tag.data = null
          commit('SET_NFC_TAG', tag)
          if(!tag.aid) commit('SET_ALERT', { notice: 'Scanned unknown Tag', variant: 'info' })
          if(!state.autocallback || state.toWrite) return resolve()
          let cb = async () => {
            await state.autocallback(state.tag)
            resolve()  
          }
          cb()
        })
      },
      nfcCallback({ dispatch, state }, err) {
        return new Promise((resolve, reject) => {
          if(!state.callback) resolve()
          else {
            let cb = async () => {
              await state.callback(state.tag, err)
              dispatch('nfcClear')
              resolve() 
            }
            cb()
          }
        })
      },
      nfcSetCallback({ commit }, { cb, auto, key }) {
        if(!key) key = Math.random().toString(36).substring(3)
        if(!auto) commit('SET_NFC_CALLBACK', { cb, key })
        else commit('SET_NFC_AUTOCALLBACK', { cb, key })
        return key
      },
      nfcClearCallback({ state, commit }, key) {
        if(!key) return console.warn('nfcClearCb: key is required')
        if(state.callbackRef.cb === key) commit('SET_NFC_CALLBACK', { cb: null })
        if(state.callbackRef.acb === key) commit('SET_NFC_AUTOCALLBACK', { cb: null })
      },
      nfcClearAll({ commit }) {
        // console.log('nfcClearAll')
        commit('SET_NFC_CALLBACK', { cb: null })
        commit('SET_NFC_AUTOCALLBACK', { cb: null })
        commit('SET_NFC_WRITE', null)
        commit('SET_NFC_TAG', null)
      }
    }
  }
  